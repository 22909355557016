import React from 'react'
import { Link } from 'react-router-dom'
 

function Home() {
  return (
    <div>
      <button><Link to="/employee">Employee</Link></button>
      {/* <button><Link to="/employer">Employer</Link></button> */}
      <p>Don't have an account? <Link to="/signup">Click here</Link></p>
    </div> 
  )
}

export default Home