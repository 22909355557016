import React, { useState } from 'react'
import { Link, Navigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { Auth, Hub } from 'aws-amplify'



function Signup() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = data => signUp(data);
  const onConfirmationSubmit = data => confirmSubmit(data)

  const [signUpSuccessful, setSignUpSuccessful] = useState(false)
  
  async function signUp(data) {
    console.log({ data })
    try {
      const { user } = await Auth.signUp({
        username: data.email,
        password: data.password,
        attributes: {
          email: data.email,
        },
        autoSignIn: {
          enabled: true,
        }
      })
      console.log(user)
      setSignUpSuccessful(true)
    } catch (error) {
      console.log('error signing up: ', error)
    }
  }

  async function confirmSubmit(data) {
    await Auth.confirmSignUp(data.email, data.confirmation)
  }

  function listenToAutoSignInEvent() {
    Hub.listen('auth', ({ payload }) => {
      const { event } = payload;
      if (event === 'autoSignIn') {
        const user = payload.data
        console.log({ user })
      } else if (event === 'autoSignIn_failure') {
        console.log('go to signin again')
      }
    })
  }
  return (
    <div>
      <h3>Sign up for an account here</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
          <input type="email" placeholder="email" {...register("email", {required: true})} /><br />
          <input type="password" placeholder="password" {...register("password", {required: true})} /><br /><br />

          <input type="submit" />
      </form>
      {signUpSuccessful && (
        <div>
          <p>confirm your email</p>
          <form onSubmit={handleSubmit(onConfirmationSubmit)}>
            <input type="text" placeholder="confirmation code" {...register("confirmation", {required: true})}/><br />
            <input type="submit" />
          </form>
        </div>
      )}
      <button><Link to='/'>Back</Link></button>
    </div>
  )
}

export default Signup