import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { useForm } from 'react-hook-form'
import { Auth, Amplify, API, graphqlOperation, Storage } from 'aws-amplify'
import awsconfig from '../../aws-exports'
import * as mutations from '../../graphql/mutations'


Amplify.configure(awsconfig)

function EmployeeForm() {
  const { register, handleSubmit, formState: { error } } = useForm()
  const onSubmit = data => handleSubmitForm(data)

  const handleSubmitForm = async (data) => {
    const { resume } = data
    const signUpData = {
      email: data.email,
      password: data.password,
    }

    const userData = {
      fullName: data.fullName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      radius: data.radius,
      zipCode: data.zipCode,
    }


    const cognitoUser = await signUp(signUpData)
    const userRes = await createUserData(userData, cognitoUser)
    console.log({ userRes })
    console.log('resume in handle: ', resume[0])
    uploadResume(resume[0])
    // need to create the account
    // need to submi the form data to employee table
  }

  const createUserData = async (userData, cognitoUser) => {
    console.log('make the api to create the user table', userData, cognitoUser)
    const newUserData = await API.graphql(graphqlOperation(mutations.createEmployee, { input: userData }))
    console.log({ newUserData })
    return newUserData
  }

  const uploadResume = async (resume) => {
    console.log(resume)
    const result = await Storage.put(resume.name, resume, {
      level: "public",
      contentType: resume.type,
    })
    console.log({ result })
  }


  const signUp = async (data) => {
    const { email, password } = data
    try {
      const { user } = await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
        },
      })
      return user
    } catch (error) {
      console.log('error in signup: ', error)
    }
  }

  return (
    <div className="App">
      <form onSubmit={handleSubmit(onSubmit)} style={{ flex: 1 }}>
          <input type="text" placeholder="Full Name" {...register("fullName", {required: true, maxLength: 80})} /><br />
          <input type="text" placeholder="Email" {...register("email", {required: true, pattern: /^\S+@\S+$/i})} /><br />
          <input type="tel" placeholder="Phone Number" {...register("phoneNumber", {required: true, maxLength: 12})} /><br />
          <input type="text" placeholder="radius" {...register("radius", {required: true})} /><br />
          <input type="text" placeholder="zip code" {...register("zipCode", {required: true})} /><br /><br />


          <input type="password" placeholder="password" {...register("password", {required: true})} /><br />
          <input type="password" placeholder="confirm password" {...register("password", {required: true})} /><br /><br />


          <label>Classification</label><br />
          <select {...register("classification")}>
            <option value="Electrician">Electrician</option>
            <option value=" Plumber"> Plumber</option>
            <option value=" Carpenter"> Carpenter</option>
            <option value=" Operating Engineer"> Operating Engineer</option>
            <option value=" Demolition"> Demolition</option>
            <option value=" Mechanical"> Mechanical</option>
            <option value=" Steel"> Steel</option>
            <option value=" Glass and Glazing"> Glass and Glazing</option>
            <option value=" HVAC"> HVAC</option>
            <option value=" Roof"> Roof</option>
            <option value=" Concrete"> Concrete</option>
            <option value=" Asbestos"> Asbestos</option>
            <option value=" Elevator"> Elevator</option>
            <option value=" Painting and Coating"> Painting and Coating</option>
            <option value=" Fencing"> Fencing</option>
            <option value=" Casework and Millwork"> Casework and Millwork</option>
          </select><br /><br />

          <input {...register("resume")} type="file" id="myResume" name="resume" /><br />
          <input type="submit" />
      </form>

      <button><Link to='/'>Back</Link></button>
    </div>
  )
}

export default EmployeeForm; 