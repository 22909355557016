import React, { useState } from "react";
import { Routes, Route, Link } from 'react-router-dom'
import Amplify, { Auth } from "aws-amplify";
import './App.css';

import { Authenticator, Button, Heading } from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import awsExports from '../aws-exports'

import EmployeeForm from './employee/EmployeeForm';
import Home from './Home'
import Signup from './auth/Signup'

Amplify.configure(awsExports)
Auth.configure(awsExports)
function App() {
  return (
    <div className="App">
      <h1>Welcome to Intel Con workforce</h1> 
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/employee" exact element={<EmployeeForm />}/>
        <Route path="/employer" exact element={null}/>
        <Route path="/signup" exact element={<Signup />} />
      </Routes>
    </div>
  );
}

export default App;
