/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://wxcdz353ojhr5bfpezhwrj2iqu.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-mz3zqbung5bmvcm4l6bsiv7mju",
    "aws_cognito_identity_pool_id": "us-west-2:8c9e004d-d394-4e17-9b14-4ddb40957521",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_XtRVNlbvm",
    "aws_user_pools_web_client_id": "6gigctapppq01db65dvjih5tiu",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "workforce-storage-64e0c64772513-prod",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
